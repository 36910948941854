.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #074a5a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: rgb(255, 135, 30);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Global styles for our theme */
:root {
  --primary-color: #074a5a;
  --secondary-color: rgb(255, 135, 30);
  --primary-light: rgba(7, 74, 90, 0.1);
  --secondary-light: rgba(255, 135, 30, 0.1);
}

.btn {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  border-radius: 50px; /* Pill shape */
  padding: 10px 20px;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn i {
  margin-right: 8px;
}

.btn-primary {
  border-color: #053a48;
}

.btn-success {
  border-color: rgb(224, 118, 26);
}

.modal-content {
  border-radius: 20px;
  border: none;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.form-control, .form-control.custom-select, .custom-select {
  border-radius: 50px !important;
  padding: 12px 20px !important;
  border: 2px solid #e9ecef !important;
  transition: all 0.3s ease !important;
  margin-bottom: 1rem !important;
}

/* Make form fields taller to match dropdowns */
select.form-control, select.custom-select,
input.form-control, textarea.form-control {
  height: auto !important;
  min-height: 58px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.form-control:focus, .form-control.custom-select:focus, .custom-select:focus {
  border-color: #074a5a !important;
  box-shadow: 0 0 0 0.2rem rgba(7, 74, 90, 0.15) !important;
}

/* Override bootstrap select arrow position */
.custom-select {
  background-position: right 1.25rem center !important;
}

/* Pill-shaped badges and tags */
.badge {
  border-radius: 50px;
  padding: 8px 15px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  border: 1px solid transparent;
}

.badge-primary {
  background-color: rgba(7, 74, 90, 0.1);
  color: #074a5a;
  border-color: #074a5a;
}

.badge-success {
  background-color: rgba(255, 135, 30, 0.1);
  color: rgb(255, 135, 30);
  border-color: rgb(255, 135, 30);
}

/* Section headers with icon and border */
.section-header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid rgba(7, 74, 90, 0.2);
}

.section-header i {
  font-size: 1.5rem;
  margin-right: 10px;
  color: #074a5a;
}

.section-header h5 {
  margin-bottom: 0;
}

/* Custom dividers */
.divider {
  height: 2px;
  background: linear-gradient(to right, rgba(7, 74, 90, 0.1), rgba(7, 74, 90, 0.5), rgba(7, 74, 90, 0.1));
  margin: 2rem 0;
}