.selected-primary {
    background-color: #074a5a;
}

.selected-success {
    background-color: rgb(255, 135, 30);
}

.white-bg {
    background-color: white;
}

.card {
    border-radius: 20px; /* More pill-shaped */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 2px solid transparent;
    overflow: hidden;
    margin-bottom: 1.5rem;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.card.selected {
    border-color: #074a5a;
    box-shadow: 0 8px 20px rgba(7, 74, 90, 0.3);
}

.card-header {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    border-radius: 18px 18px 0 0 !important;
    padding: 1rem 1.5rem;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
}

.card-header i {
    margin-right: 10px;
    font-size: 1.25rem;
}

.card-header.bg-primary {
    background-color: #074a5a !important;
}

.card-header.bg-success {
    background-color: rgb(255, 135, 30) !important;
}

.card-header.bg-warning {
    background-color: #ffc107 !important;
}

.card-header.bg-info {
    background-color: #17a2b8 !important;
}

.card-body {
    padding: 1.5rem;
}

/* Feature list inside card */
.feature-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 1.5rem;
}

.feature-list li {
    padding: 8px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.selected-primary .feature-list li,
.selected-success .feature-list li,
.selected-warning .feature-list li,
.selected-info .feature-list li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.feature-list li:last-child {
    border-bottom: none;
}

.feature-list li i {
    color: #074a5a;
    margin-right: 10px;
}

/* Feature list in selected cards */
.selected-primary .feature-list li i,
.selected-success .feature-list li i,
.selected-warning .feature-list li i,
.selected-info .feature-list li i {
    color: white;
}

/* Price tag */
.price-tag {
    display: inline-block;
    background-color: rgba(7, 74, 90, 0.1);
    color: #074a5a;
    padding: 8px 20px;
    border-radius: 50px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    border: 2px solid #074a5a;
    margin-bottom: 1rem;
}

/* Price tag within selected cards */
.selected-primary .price-tag,
.selected-success .price-tag,
.selected-warning .price-tag,
.selected-info .price-tag {
    background-color: white;
    color: #074a5a;
    border-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.tag-icon-selected {
    color: #074a5a !important;
}

.discount-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgb(255, 135, 30);
    color: white;
    border-radius: 50px;
    padding: 5px 12px;
    font-size: 0.8rem;
    font-weight: 600;
    transform: rotate(10deg);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Input field inside card */
.card .form-control {
    border: 2px solid rgba(7, 74, 90, 0.2);
    margin-bottom: 1rem;
}

.card .btn {
    width: 100%;
}