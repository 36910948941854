@media (max-width: 500px) {
    .card-columns {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }
  }
  
  @media (min-width: 500px) {
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3 !important;
    }
  }

.modal-header {
  background-color: #074a5a;
  color: white;
  border-bottom: 3px solid rgb(255, 135, 30);
  border-radius: 20px 20px 0 0 !important;
  padding: 1.5rem;
}

.modal-title {
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.modal-title::before {
  content: "\f0d1";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  margin-right: 10px;
  font-size: 1.5rem;
}

.btn-primary {
  background-color: #074a5a !important;
  border-color: #074a5a !important;
}

.btn-success {
  background-color: rgb(255, 135, 30) !important;
  border-color: rgb(255, 135, 30) !important;
}

.modal-body {
  padding: 2rem;
}

.modal-footer {
  border-top: 2px solid rgba(7, 74, 90, 0.1);
  padding: 1.25rem 2rem;
  border-radius: 0 0 20px 20px;
}

/* Section headings with icons */
h5 {
  color: #074a5a;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
}

h5.window-cleaning::before {
  content: "\f2cc";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  margin-right: 10px;
  color: #074a5a;
}

h5.power-washing::before {
  content: "\f043";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  margin-right: 10px;
  color: #074a5a;
}

h5.screen-repair::before {
  content: "\f0b1";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  margin-right: 10px;
  color: #074a5a;
}

h5.blind-cleaning::before {
  content: "\f0c9";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  margin-right: 10px;
  color: #074a5a;
}

/* Stylish horizontal rule */
hr {
  height: 2px;
  background: linear-gradient(to right, rgba(7, 74, 90, 0.1), rgba(7, 74, 90, 0.5), rgba(7, 74, 90, 0.1));
  border: none;
  margin: 1.5rem 0;
}

.alert-success {
  background-color: rgba(255, 135, 30, 0.1) !important;
  color: rgb(255, 135, 30) !important;
  border: 2px solid rgb(255, 135, 30) !important;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(255, 135, 30, 0.15);
  padding: 1.25rem;
}

.alert-success h4 {
  color: rgb(255, 135, 30);
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
}

.alert-success h4::before {
  content: "\f0a3";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  margin-right: 10px;
}

/* Quote details section */
.quote-details {
  background-color: rgba(7, 74, 90, 0.05);
  border-radius: 20px;
  padding: 1.5rem;
  border: 2px solid rgba(7, 74, 90, 0.1);
  margin-top: 2rem;
}

.quote-details h6 {
  color: #074a5a;
  font-weight: 600;
  border-bottom: 1px solid rgba(7, 74, 90, 0.1);
  padding-bottom: 0.75rem;
  margin-bottom: 1rem;
}

/* Page indicators */
.page-indicator {
  display: inline-flex;
  align-items: center;
  background-color: rgba(7, 74, 90, 0.1);
  border-radius: 50px;
  padding: 5px 15px;
  color: #074a5a;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 0.85rem;
}