/* General Information Page Styles */
.general-info-container {
  padding: 2rem;
}

.intro-text {
  background-color: rgba(7, 74, 90, 0.05);
  border: 2px solid rgba(7, 74, 90, 0.1);
  border-radius: 20px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  position: relative;
}

.intro-text::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 20px;
  width: 20px;
  height: 20px;
  background-color: rgba(7, 74, 90, 0.05);
  border-top: 2px solid rgba(7, 74, 90, 0.1);
  border-left: 2px solid rgba(7, 74, 90, 0.1);
  transform: rotate(45deg);
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.company-logo {
  max-width: 200px;
  height: auto;
}

.form-group {
  margin-bottom: 2rem !important;
}

.discount-alert {
  border-radius: 20px;
  padding: 1.5rem;
  margin: 2rem 0;
  background-color: rgba(255, 135, 30, 0.1) !important;
  border: 2px solid rgb(255, 135, 30) !important;
  box-shadow: 0 4px 12px rgba(255, 135, 30, 0.1);
}

.discount-header {
  color: rgb(255, 135, 30);
  font-family: 'Montserrat', sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(255, 135, 30, 0.2);
  display: flex;
  align-items: center;
}

.discount-header i {
  margin-right: 0.5rem;
}

.discount-text {
  margin-bottom: 0;
}

.form-section {
  background-color: white;
  border-radius: 20px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.modal-header.general-info-header {
  display: flex;
  align-items: center;
  position: relative;
  padding: 1.5rem 2rem;
}

.modal-header.general-info-header:before {
  content: "\f015";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 1.5rem;
  margin-right: 1rem;
  color: white;
}

.form-label {
  font-weight: 600;
  color: #074a5a;
  margin-bottom: 0.8rem;
  font-size: 1.05rem;
}

.form-row {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .general-info-container {
    padding: 1rem;
  }
}