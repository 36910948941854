@media print {
    .no-printme {
      display: none !important;
    }
    .printme {
      display: block !important;
    }
    @page {
      size: 1600px !important;
    }
  
    body,
    html {
      width: 1600px;
    }
  }
  
/* Personal info specific styling */
.form-group {
  margin-bottom: 1.5rem !important;
}

.form-label {
  font-weight: 600;
  color: #074a5a;
  margin-bottom: 0.8rem;
  font-size: 1.05rem;
}

.form-control {
  font-size: 1rem !important;
}

/* Styling for the number input spinners */
input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
  height: 58px;
  margin-right: 10px;
}

/* Style for the modal header */
.modal-header {
  border-bottom: 3px solid rgb(255, 135, 30);
}

.modal-title {
  display: flex;
  align-items: center;
}

.modal-title::before {
  content: "\f007";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  margin-right: 10px;
  font-size: 1.5rem;
}

.modal-footer .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.modal-footer .btn-secondary::before {
  content: "\f060";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  margin-right: 8px;
}

.modal-footer .btn-primary::before {
  content: "\f274";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  margin-right: 8px;
}

/* Page indicator pill */
.modal-footer span {
  display: inline-flex;
  align-items: center;
  background-color: rgba(7, 74, 90, 0.1);
  border-radius: 50px;
  padding: 5px 15px;
  color: #074a5a;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 0.85rem;
}

.modal-footer span::before {
  content: "\f15c";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  margin-right: 8px;
}
  